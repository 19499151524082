<template>
  <div>
    <h1>product sorting</h1>
    <div class="d-flex justify-content-center">
      <b-form-select
        v-model="categories_id"
        :options="formattedCategoriesOptions"
        class="mr-1"
      />
      <b-button variant="relief-primary" @click="selectCategories">
        Select
      </b-button>
    </div>
    <hr />

    <!-- <b-list-group> -->
    <draggable v-model="products_data" @end="onDragEnd">
      <!-- <b-list-group-item v-for="(item, index) in products_data" :key="index"> -->
      <b-alert
        variant="success"
        show
        v-for="(item, index) in products_data"
        :key="index"
        class="p-1"
      >
        <div class="d-flex justify-content-between">
          <div>
            {{ item.name }}
          </div>
          <div>{{ item.rental_price }} บาท/วัน</div>
        </div>
      </b-alert>

      <!-- </b-list-group-item> -->
    </draggable>
    <!-- </b-list-group> -->

    <hr />
    <div class="text-right">
      <b-button variant="relief-success" @click="SaveProduct"> Save </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BFormSelect,
  BListGroupItem,
  BListGroup,
  BAlert,
} from "bootstrap-vue";
import product_categories from "@/assets/product_categories_db.json";

import draggable from "vuedraggable";
export default {
  computed: {
    // Format the product_categories_options for use in b-form-select
    formattedCategoriesOptions() {
      return this.product_categories_options
        .map((category) => ({
          text: category.name, // Displayed to the user
          value: category.sort_id, // Underlying value
        }))
        .sort((a, b) => {
          return a.sort_id && b.sort_id
            ? a.sort_id.localeCompare(b.sort_id)
            : 0;
        });
    },
  },
  components: {
    BButton,
    draggable,
    BListGroup,
    BFormSelect,
    BListGroupItem,
    product_categories,
    BAlert,
  },
  data() {
    return {
      product_categories_options: product_categories,
      categories_id: null,
      products_data: [],
      afterSort: [],
      select_cat: null,
    };
  },
  methods: {
    onDragEnd() {
      this.products_data.forEach((product, index) => {
        let indexing = index + 1;
        indexing = indexing.toString().padStart(3, "0");
        product.sorting_order = Number(`${this.select_cat.sort_id}${indexing}`);
      });
    },
    selectCategories() {
      this.select_cat = product_categories.find(
        (x) => x.sort_id === this.categories_id
      );

      this.$http({
        method: "POST",
        url: `/product/search/category`,
        data: {
          categories: this.select_cat.name,
        },
      }).then(async (x) => {
        this.products_data = x.data.data;
        this.products_data = this.products_data.map((x) => {
          const temp = {};
          temp.name = x.name;
          temp.sorting_order = x.sorting_order;
          temp.rental_price = x.rental_price;
          temp._id = x._id;
          return temp;
        });
      });
    },
    SaveProduct() {
      this.onDragEnd();
      const save_data = this.products_data.map((product) => ({
        sorting_order: product.sorting_order,
        _id: product._id,
      }));

      this.$http({
        method: "POST",
        url: `/product/update-bulk`,
        data: save_data,
      }).then(async (x) => {
        this.selectCategories();
      });
    },
  },
};
</script>
