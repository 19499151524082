<template>
  <div>
    <b-modal
      id="modal-add-product"
      ref="modal-add-product"
      title="เพิ่มอุปกรณ์ใหม่"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <validation-observer ref="addProduct">
        <b-form @submit.prevent="addProduct">
          <!-- ชื่ออุปกรณ์ -->
          <b-form-group label="ชื่อในระบบ">
            <validation-provider
              #default="{ errors }"
              name="Product Name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ชื่อในเว็บ -->
          <b-form-group label="ชื่อในเว็บ">
            <validation-provider
              #default="{ errors }"
              name="Web Name"
              rules="required"
            >
              <b-form-input
                v-model="form.web_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- slug -->
          <b-form-group label="slug">
            <validation-provider
              #default="{ errors }"
              name="SLUG"
              rules="required"
            >
              <b-form-input
                v-model="form.slug"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            @click="generateSlug(form.web_name)"
            size="sm"
            variant="success"
            class="mb-1"
            >สร้าง Link</b-button
          >

          <!-- ชื่อในสัญญา -->
          <b-form-group label="ชื่อในสัญญา">
            <validation-provider
              #default="{ errors }"
              name="Lease Name"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Brand -->
          <b-form-group label="ยี่ห้อ">
            <validation-provider
              #default="{ errors }"
              name="Brand"
              rules="required"
            >
              <b-form-input
                v-model="form.brand"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ประเภทอุปกรณ์ -->
          <h5 class="text-white">Category</h5>
          <b-form-select
            class="mb-1"
            v-model="form.categories"
            :options="product_categories_options"
          />

          <!-- Item Type -->
          <b-form-group label="Type">
            <validation-provider
              #default="{ errors }"
              name="item_type"
              rules="required"
            >
              <b-form-input
                v-model="form.item_type"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            eg. camera , lens , accessories
          </b-form-group>

          <!-- ค่าเช่า -->
          <b-form-group label="ค่าเช่า">
            <validation-provider
              #default="{ errors }"
              name="Rental Price"
              rules="required"
            >
              <b-form-input
                v-model="form.rental_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ยอดวางประกัน -->
          <b-form-group label="ยอดวางประกัน 1">
            <validation-provider
              #default="{ errors }"
              name="Deposit Price"
              rules="required"
            >
              <b-form-input
                v-model="form.deposit_1"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ยอดวางประกัน -->
          <b-form-group label="ยอดวางประกัน 2">
            <validation-provider #default="{ errors }" name="Deposit Price 2">
              <b-form-input v-model="form.deposit_2" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาในสัญญาเช่า -->
          <b-form-group label="ราคาในสัญญาเช่า">
            <validation-provider
              #default="{ errors }"
              name="Lease Price"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ไม่แสดงหน้าแรก -->
          <b-form-group>
            <h5>ขึ้นหน้าแรก</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.web_hide_new_product"
                name="some-radios9"
                :value="false"
              >
                ขึ้นหน้าแรกปกติ
              </b-form-radio>
              <b-form-radio
                v-model="form.web_hide_new_product"
                name="some-radios9"
                :value="true"
              >
                ไม่แสดง
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- การจัดส่ง -->
          <b-form-group>
            <h5>การจัดส่ง</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.shipable"
                name="some-radios"
                :value="true"
              >
                ส่งได้
              </b-form-radio>
              <b-form-radio
                v-model="form.shipable"
                name="some-radios"
                :value="false"
              >
                ส่งไม่ได้
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- แสดงหน้าเว็บ -->
          <b-form-group>
            <h5>แสดงหน้าเว็บ</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.web_enable"
                name="some-radios2"
                :value="true"
              >
                แสดงหน้าเว็บ
              </b-form-radio>
              <b-form-radio
                v-model="form.web_enable"
                name="some-radios2"
                :value="false"
              >
                ไม่แสดงหน้าเว็บ
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- การคิดค่าเช่า -->
          <b-form-group>
            <h5>การคิดค่าเช่า</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.flat_rate"
                name="some-radios4"
                :value="false"
              >
                ปกติ
              </b-form-radio>
              <b-form-radio
                v-model="form.flat_rate"
                name="some-radios4"
                :value="true"
              >
                ราคาเดียว
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- Is GoPro -->
          <b-form-group>
            <h5>GoProlineup</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_gopro"
                name="some-radios5"
                :value="true"
              >
                ใช่
              </b-form-radio>
              <b-form-radio
                v-model="form.is_gopro"
                name="some-radios5"
                :value="false"
              >
                ไม่
              </b-form-radio>
            </div>
          </b-form-group>
          <!-- IS KIT -->
          <b-form-group>
            <h5>อุปกรณ์จัดชุด</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_kit"
                name="some-radios6"
                :value="true"
              >
                ใช่
              </b-form-radio>
              <b-form-radio
                v-model="form.is_kit"
                name="some-radios6"
                :value="false"
              >
                ไม่
              </b-form-radio>
            </div>
          </b-form-group>
          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              เพิ่มอุปกรณ์
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import ProductCategories from "@/assets/product_categories_db.json";

import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  computed: {
    product_categories_options() {
      return ProductCategories.map((x) => x.name);
    },
  },
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  data() {
    return {
      form: {
        web_enable: true,
        admin_enable: true,
        shipable: true,
        flat_rate: false,
        is_gopro: false,
        is_kit: false,
        web_hide_new_product: false,
        web_name: "",
        slug: "",
      },

      required,
      loading: false,
    };
  },
  created() {},
  // watch: {
  //   "form.web_name": {
  //     handler: function (newWebName) {
  //       // Automatically update the slug when web_name changes
  //       this.form.slug = this.generateSlug(newWebName);
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    validationForm() {
      this.$refs.addProduct.validate().then((success) => {
        this.form.main_image = "";
        this.form.sorting_order = 999999;
        if (success) {
          this.$http({
            method: "POST",
            url: "/product",
            data: this.form,
          }).then((x) => {
            this.$router.push("/product/view/" + x.data._id);
          });
        }
      });
    },
    cancel() {
      this.$refs["modal-add-product"].hide();
    },
    generateSlug(webName) {
      this.form.slug = webName
        .normalize("NFD") // Normalize to decompose characters like accents
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks (accents, etc.)
        .replace(/[^\w\s-]+/g, "") // Remove special characters except hyphens
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/-+/g, "-") // Replace multiple hyphens with a single one
        .replace(/^-+|-+$/g, "") // Trim hyphens from start and end
        .toLowerCase(); // Convert to lowercase
    },
  },
};
</script>

<style></style>
